import { Grid } from "@mui/material";
import LoginCard from "../components/LoginCard";
import { Margin } from "@mui/icons-material";

function Admin() {
  return (
  
   
        <LoginCard />
   
      
   
  );
}

export default Admin;
// We just need to ensure this works